import { EthereumClient, w3mConnectors, w3mProvider } from "@web3modal/ethereum"

import { configureChains, createConfig } from "wagmi"
import { mainnet, polygon, optimism, arbitrum } from "wagmi/chains"

const chains = [mainnet, polygon, optimism, arbitrum]
if (!process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID) {
  throw new Error("You need to provide NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID env variable")
}
const projectId = process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID

// Wagmi client
const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
})

// Web3Modal Ethereum Client
const ethereumClient = new EthereumClient(wagmiConfig, chains)

export { wagmiConfig, ethereumClient }
