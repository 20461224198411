import "../styles/globals.css"
import "../public/profile/collection/images.json"
import "../public/profile/collection/avatar/images.json"
import "../public/profile/collection/featured/images.json"
import "../public/profile/collection/tier/images.json"
import "../public/profile/collection/nft-media/images.json"
import "@fontsource/poppins"
import "@fontsource/roboto"
import type { AppProps } from "next/app"
import { ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import Theme from "appearance/Theme"
import StripeClient from "services/stripe/StripeClient"
import { CartProvider } from "use-shopping-cart"
import { SessionProvider } from "next-auth/react"

import { Web3Modal } from "@web3modal/react"
import { WagmiConfig } from "wagmi"
import { ethereumClient, wagmiConfig } from "services/wallet-connect/WalletConnectService"

function App({ Component, pageProps: { session, ...pageProps } }: AppProps) {
  return (
    <>
      <SessionProvider session={session} refetchInterval={5 * 60}>
        <CartProvider mode="checkout-session" stripe={StripeClient()} currency={"usd"}>
          <WagmiConfig config={wagmiConfig}>
            <ThemeProvider theme={Theme}>
              <CssBaseline>
                <Component {...pageProps} />
              </CssBaseline>
            </ThemeProvider>
          </WagmiConfig>
        </CartProvider>
      </SessionProvider>

      <Web3Modal projectId={`${process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID}`} ethereumClient={ethereumClient} />
    </>
  )
}

export default App
