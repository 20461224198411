import { alpha, createTheme } from "@mui/material"

declare module "@mui/material/styles" {
  interface Palette {
    accent: Palette["primary"]
  }
  interface PaletteOptions {
    accent: PaletteOptions["primary"]
  }
  interface Palette {
    black: Palette["primary"]
  }
  interface PaletteOptions {
    black: PaletteOptions["primary"]
  }
  interface Palette {
    white: Palette["primary"]
  }
  interface PaletteOptions {
    white: PaletteOptions["primary"]
  }
  interface Palette {
    gray: Palette["primary"]
  }
  interface PaletteOptions {
    gray: PaletteOptions["primary"]
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    accent: true
  }
}

declare module "@mui/material/AppBar" {
  interface AppBarPropsColorOverrides {
    accent: true
    black: true
  }
}

declare module "@mui/material/Badge" {
  interface BadgePropsColorOverrides {
    accent: true
    black: true
  }
}

declare module "@mui/material/Icon" {
  interface IconPropsColorOverrides {
    white: true
  }
}

declare module "@mui/material/Radio" {
  interface RadioPropsColorOverrides {
    accent: true
  }
}

declare module "@mui/material/CircularProgress" {
  interface CircularProgressPropsColorOverrides {
    accent: true
  }
}

const Theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#1E0832",
    },
    secondary: {
      main: "#300D4F",
    },
    accent: {
      main: "#992DFC",
    },
    divider: alpha("#FFFFFF", 0.3),
    error: {
      main: "#F44335",
    },
    black: {
      main: "#000000",
    },
    white: {
      main: "#FFFFFF",
    },
    gray: {
      main: "#4D4D4D",
    },
    background: {
      default: "#FAFAFA",
    },
  },
  shape: {
    borderRadius: 5,
  },
  typography: {
    fontFamily: ["Poppins", "Roboto"].join(","),
    h1: {
      fontSize: 25,
      fontWeight: 500,
      textTransform: "uppercase",
      color: "white",
    },
    h2: {
      fontSize: 22,
      fontWeight: 500,
      textTransform: "none",
      color: "white",
    },
    h3: {
      fontSize: 20,
      fontWeight: 500,
      textTransform: "uppercase",
      color: "white",
    },
    h4: {
      fontSize: 18,
      fontWeight: 500,
      textTransform: "uppercase",
      color: "white",
    },
    h5: {
      fontSize: 16,
      fontWeight: 500,
      textTransform: "uppercase",
      color: "white",
    },
    h6: {
      fontSize: 14,
      fontWeight: 500,
      color: "white",
    },
    subtitle1: {
      fontSize: 16,
      color: "#A3A3A3",
    },
    subtitle2: {
      fontSize: 12,
      textTransform: "uppercase",
    },
    caption: {
      fontSize: 14,
      color: alpha("#FFFFFF", 0.8),
    },
    body1: {
      fontSize: 14,
      color: "white",
      textTransform: "none",
    },
    body2: {
      fontSize: 16,
      color: "#B4B4B4",
    },
  },
  components: {
    MuiIcon: {
      defaultProps: {
        baseClassName: "lnr",
        fontSize: "small",
        color: "white",
      },
      styleOverrides: {
        root: {
          color: "#FFFFFF",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          "@media (min-width: 1200px)": {
            minHeight: 80,
            color: "white",
            paddingLeft: 70,
            paddingRight: 70,
          },
          "@media (min-width: 0px)": {
            minHeight: 80,
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          "@media (min-width: 1200px)": {
            paddingLeft: 70,
            paddingRight: 70,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
        color: "accent",
      },
      styleOverrides: {
        root: {
          height: 32,
          borderRadius: 16,
          color: "#FFFFFF",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "#151515",
          borderBottomWidth: 3,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& label.Mui-focused": {
            color: "white",
          },

          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderWidth: 0,
            },
            "&:hover fieldset": {
              borderWidth: 0,
            },
            "&.Mui-focused fieldset": {
              borderWidth: 0,
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: "white",
          borderWidth: 0,
          fontSize: 13,
          borderRadius: 5,
          backgroundColor: alpha("#FFFFFF", 0.15),

          "& .MuiSvgIcon-root": {
            color: "white",
          },

          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderWidth: 0,

            "& fieldset": {
              borderWidth: 0,
            },
          },

          "& fieldset": {
            borderWidth: 0,
          },
          "&:hover fieldset": {
            borderWidth: 0,
          },
          "&.Mui-focused fieldset": {
            borderWidth: 0,

            "&.MuiOutlinedInput-notchedOutline": {
              borderWidth: 0,
            },
          },
          "&:focus fieldset": {
            borderWidth: 0,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "& .MuiInputLabel-asterisk": {
            color: "#992DFC",
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: "#212121",
          borderRadius: 5,
        },
      },
    },
    MuiCardMedia: {
      styleOverrides: {
        root: {
          borderRadius: 5,
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        color: "accent",
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "24px 32px",
          backgroundColor: "#212121",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: "#212121",
        },
      },
    },
    MuiCircularProgress: {
      defaultProps: {
        color: "accent",
      },
    },
  },
})

export default Theme
